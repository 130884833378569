import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: center;
`;

const TeamInputField = styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1rem;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
`;

const Button = styled.button`
  background-color: ${props => props.$gray ? '#95a5a6' : '#3498db'};
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.$gray ? '#7f8c8d' : '#2980b9'};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
`;

const TeamInput = ({ teams, onComplete, onBack }) => {
  const [teamNames, setTeamNames] = useState(teams.map(team => team.name || ''));

  useEffect(() => {
    setTeamNames(teams.map(team => team.name || `チーム ${team.id.split('-')[1]}`));
  }, [teams]);

  const handleInputChange = (index, value) => {
    const newTeamNames = [...teamNames];
    newTeamNames[index] = value.trim() || `チーム ${index + 1}`;
    setTeamNames(newTeamNames);
  };

  const handleSubmit = () => {
    const updatedTeams = teams.map((team, index) => ({
      ...team,
      name: teamNames[index]
    }));
    onComplete(updatedTeams);
  };

  return (
    <Container>
      <Title>チーム名を入力してください</Title>
      {teams.map((team, index) => (
        <TeamInputField key={team.id}>
          <Label>チーム {index + 1}</Label>
          <Input
            type="text"
            value={teamNames[index]}
            onChange={(e) => handleInputChange(index, e.target.value)}
            placeholder={`チーム ${index + 1}`}
          />
        </TeamInputField>
      ))}
      <ButtonContainer>
        <Button $gray onClick={onBack}>戻る</Button>
        <Button onClick={handleSubmit}>次へ</Button>
      </ButtonContainer>
    </Container>
  );
};

export default TeamInput;