// components/BackToTopButton.js
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: #34495e;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;

  &:hover {
    background-color: #2c3e50;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const BackToTopButton = ({ onClick }) => {
  return <StyledButton onClick={onClick}>トップに戻る</StyledButton>;
};

export default BackToTopButton;