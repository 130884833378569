import React, { useState } from 'react';
import styled from 'styled-components';

const StrategyGuideContainer = styled.div`
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  width: 100%;
  margin: auto;
  padding: 10px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding: 20px;
    max-width: 600px;
  }
`;


const GuideTitle = styled.h1`
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 15px;
`;

const CategoryTitle = styled.h2`
  cursor: pointer;
  background-color: #3498db;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const CategoryContent = styled.div`
  display: ${props => props.isActive ? 'block' : 'none'};
  padding: 10px;
  border: 1px solid #3498db;
  border-radius: 0 0 5px 5px;
  font-size: 0.9rem;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const StrategyGuide = () => {
  const [activeCategory, setActiveCategory] = useState(null);

  const toggleCategory = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
  };

  return (
    <StrategyGuideContainer>
      <GuideTitle>モルック戦略ガイド</GuideTitle>

      <div>
        <CategoryTitle onClick={() => toggleCategory('pattern-analysis')}>1. 得点パターン詳細分析</CategoryTitle>
        <CategoryContent isActive={activeCategory === 'pattern-analysis'}>
        <h3>25点を狙う場合</h3>
            <p>全16パターン：奇数のみ3、偶数のみ0、混合13</p>
            <h3>25点を狙う場合（全16パターン：奇数のみ3、偶数のみ0、混合13）</h3>
            <ol>
                <li>12 + 12 + 1 (3手)</li>
                <li>12 + 11 + 2 (3手)</li>
                <li>12 + 10 + 3 (3手)</li>
                <li>12 + 9 + 4 (3手)</li>
                <li>12 + 8 + 5 (3手)</li>
                <li>12 + 7 + 6 (3手)</li>
                <li>11 + 11 + 3 (3手)</li>
                <li>11 + 10 + 4 (3手)</li>
                <li>11 + 9 + 5 (3手)</li>
                <li>11 + 8 + 6 (3手)</li>
                <li>10 + 10 + 5 (3手)</li>
                <li>10 + 9 + 6 (3手)</li>
                <li>10 + 8 + 7 (3手)</li>
                <li>9 + 9 + 7 (3手)</li>
                <li>8 + 8 + 9 (3手)</li>
                <li>7 + 7 + 11 (3手)</li>
            </ol>

            <h4>奇数のみパターン：</h4>
            <ul>
                <li>9 + 9 + 7</li>
                <li>7 + 7 + 11</li>
                <li>11 + 11 + 3</li>
            </ul>

            <h4>戦略的考察：</h4>
            <ul>
                <li>12を含むパターンが6つあり、最も重要なスキットル</li>
                <li>11を含むパターンも6つあり、次に重要</li>
                <li>全てのパターンが3手で構成されている</li>
                <li>奇数のみのパターンは3つしかないため、偶数スキットルの制御が重要</li>
            </ul>

            <h4>封じ込め戦略：</h4>
            <ul>
                <li>12を封じる: 6パターン減少、奇数パターン割合30%に増加</li>
                <li>11を封じる: 4パターン減少、奇数のみパターン8.3%に減少</li>
                <li>10を封じる: 3パターン減少、奇数パターン割合23%に若干増加</li>
                <li>9を封じる: 3パターン減少、奇数のみパターン7.7%に減少</li>
            </ul>            
                        <h3>24点を狙う場合</h3>
                        <p>全18パターン：奇数のみ0、偶数のみ4、混合14</p>
            <h3>24点を狙う場合（全18パターン：奇数のみ0、偶数のみ4、混合14）</h3>
            <ol>
                <li>12 + 12 (2手)</li>
                <li>12 + 11 + 1 (3手)</li>
                <li>12 + 10 + 2 (3手)</li>
                <li>12 + 9 + 3 (3手)</li>
                <li>12 + 8 + 4 (3手)</li>
                <li>12 + 7 + 5 (3手)</li>
                <li>11 + 11 + 2 (3手)</li>
                <li>11 + 10 + 3 (3手)</li>
                <li>11 + 9 + 4 (3手)</li>
                <li>11 + 8 + 5 (3手)</li>
                <li>11 + 7 + 6 (3手)</li>
                <li>10 + 10 + 4 (3手)</li>
                <li>10 + 9 + 5 (3手)</li>
                <li>10 + 8 + 6 (3手)</li>
                <li>9 + 8 + 7 (3手)</li>
                <li>8 + 8 + 8 (3手)</li>
                <li>6 + 6 + 12 (3手)</li>
                <li>7 + 7 + 10 (3手)</li>
            </ol>

            <h4>偶数のみパターン：</h4>
            <ul>
                <li>12 + 12</li>
                <li>10 + 10 + 4</li>
                <li>8 + 8 + 8</li>
                <li>6 + 6 + 12</li>
            </ul>

            <h4>戦略的考察：</h4>
            <ul>
                <li>12を含むパターンが6つあり、最も重要なスキットル</li>
                <li>唯一の2手パターンは12 + 12</li>
                <li>奇数のみのパターンが存在しない</li>
            </ul>

            <h4>封じ込め戦略：</h4>
            <ul>
                <li>12を封じる: 5パターン減少、偶数のみパターン15.4%に減少</li>
                <li>11を封じる: 5パターン減少、偶数のみパターン30.8%に増加</li>
                <li>10を封じる: 3パターン減少、偶数のみパターン13.3%に減少</li>
                <li>8を封じる: 3パターン減少、偶数のみパターン13.3%に減少</li>
            </ul>

            <h3>23点を狙う場合（全21パターン：奇数のみ3、偶数のみ0、混合18）</h3>
            <ol>
                <li>12 + 11 (2手)</li>
                <li>12 + 10 + 1 (3手)</li>
                <li>12 + 9 + 2 (3手)</li>
                <li>12 + 8 + 3 (3手)</li>
                <li>12 + 7 + 4 (3手)</li>
                <li>12 + 6 + 5 (3手)</li>
                <li>11 + 11 + 1 (3手)</li>
                <li>11 + 10 + 2 (3手)</li>
                <li>11 + 9 + 3 (3手)</li>
                <li>11 + 8 + 4 (3手)</li>
                <li>11 + 7 + 5 (3手)</li>
                <li>11 + 6 + 6 (3手)</li>
                <li>10 + 10 + 3 (3手)</li>
                <li>10 + 9 + 4 (3手)</li>
                <li>10 + 8 + 5 (3手)</li>
                <li>10 + 7 + 6 (3手)</li>
                <li>9 + 9 + 5 (3手)</li>
                <li>9 + 8 + 6 (3手)</li>
                <li>8 + 8 + 7 (3手)</li>
                <li>7 + 7 + 9 (3手)</li>
                <li>6 + 6 + 11 (3手)</li>
            </ol>

            <h4>奇数のみパターン：</h4>
            <ul>
                <li>11 + 11 + 1</li>
                <li>9 + 9 + 5</li>
                <li>7 + 7 + 9</li>
            </ul>

            <h4>戦略的考察：</h4>
            <ul>
                <li>12と11を含むパターンが多く、これらが最重要スキットル</li>
                <li>唯一の2手パターンは12 + 11</li>
                <li>偶数のみのパターンが存在しない</li>
            </ul>

            <h4>封じ込め戦略：</h4>
            <ul>
                <li>11を封じる: 6パターン減少、奇数のみパターン6.7%に減少</li>
                <li>10を封じる: 4パターン減少、奇数パターン割合17.6%に若干増加</li>
                <li>9を封じる: 4パターン減少、奇数のみパターン5.9%に減少</li>
                <li>12を封じる: 3パターン減少、奇数パターン割合16.7%に若干増加</li>
            </ul>

            <h3>22点を狙う場合（全22パターン：奇数のみ1、偶数のみ5、混合16）</h3>
            <ol>
                <li>12 + 10 (2手)</li>
                <li>12 + 9 + 1 (3手)</li>
                <li>12 + 8 + 2 (3手)</li>
                <li>12 + 7 + 3 (3手)</li>
                <li>12 + 6 + 4 (3手)</li>
                <li>11 + 11 (2手)</li>
                <li>11 + 10 + 1 (3手)</li>
                <li>11 + 9 + 2 (3手)</li>
                <li>11 + 8 + 3 (3手)</li>
                <li>11 + 7 + 4 (3手)</li>
                <li>11 + 6 + 5 (3手)</li>
                <li>10 + 10 + 2 (3手)</li>
                <li>10 + 9 + 3 (3手)</li>
                <li>10 + 8 + 4 (3手)</li>
                <li>10 + 7 + 5 (3手)</li>
                <li>9 + 9 + 4 (3手)</li>
                <li>9 + 8 + 5 (3手)</li>
                <li>9 + 7 + 6 (3手)</li>
                <li>8 + 8 + 6 (3手)</li>
                <li>7 + 7 + 8 (3手)</li>
                <li>6 + 6 + 10 (3手)</li>
                <li>5 + 5 + 12 (3手)</li>
            </ol>

            <h4>偶数のみパターン：</h4>
            <ul>
                <li>12 + 10</li>
                <li>11 + 11</li>
                <li>10 + 10 + 2</li>
                <li>8 + 8 + 6</li>
                <li>6 + 6 + 10</li>
            </ul>

            <h4>奇数のみパターン：</h4>
            <ul>
                <li>5 + 5 + 12</li>
            </ul>

            <h4>戦略的考察：</h4>
            <ul>
                <li>12, 11, 10を含むパターンが多く、これらが最重要スキットル</li>
                <li>2手パターンが2つ存在（12 + 10, 11 + 11）</li>
                <li>偶数のみパターンが多く、奇数のみは1つだけ</li>
            </ul>

            <h4>封じ込め戦略：</h4>
            <ul>
                <li>10を封じる: 5パターン減少、偶数パターン割合17.6%に若干減少</li>
                <li>11を封じる: 5パターン減少、偶数パターン割合17.6%に若干減少</li>
                <li>9を封じる: 4パターン減少、偶数パターン割合27.8%に増加</li>
                <li>12を封じる: 3パターン減少、偶数パターン割合15.8%に減少</li>
            </ul>

            <h3>21点を狙う場合（全24パターン：奇数のみ3、偶数のみ0、混合21）</h3>
            <ol>
                <li>12 + 9 (2手)</li>
                <li>12 + 8 + 1 (3手)</li>
                <li>12 + 7 + 2 (3手)</li>
                <li>12 + 6 + 3 (3手)</li>
                <li>12 + 5 + 4 (3手)</li>
                <li>11 + 10 (2手)</li>
                <li>11 + 9 + 1 (3手)</li>
                <li>11 + 8 + 2 (3手)</li>
                <li>11 + 7 + 3 (3手)</li>
                <li>11 + 6 + 4 (3手)</li>
                <li>10 + 10 + 1 (3手)</li>
                <li>10 + 9 + 2 (3手)</li>
                <li>10 + 8 + 3 (3手)</li>
                <li>10 + 7 + 4 (3手)</li>
                <li>10 + 6 + 5 (3手)</li>
                <li>9 + 9 + 3 (3手)</li>
                <li>9 + 8 + 4 (3手)</li>
                <li>9 + 7 + 5 (3手)</li>
                <li>9 + 6 + 6 (3手)</li>
                <li>8 + 8 + 5 (3手)</li>
                <li>8 + 7 + 6 (3手)</li>
                <li>7 + 7 + 7 (3手)</li>
                <li>6 + 6 + 9 (3手)</li>
                <li>5 + 5 + 11 (3手)</li>
            </ol>

            <h4>奇数のみパターン：</h4>
            <ul>
                <li>9 + 9 + 3</li>
                <li>7 + 7 + 7</li>
                <li>5 + 5 + 11</li>
            </ul>

            <h4>戦略的考察：</h4>
            <ul>
                <li>12, 11, 10を含むパターンが多く、これらが最重要スキットル</li>
                <li>2手パターンが2つ存在（12 + 9, 11 + 10）</li>
                <li>偶数のみのパターンが存在しない</li>
            </ul>

            <h4>封じ込め戦略：</h4>
            <ul>
                <li>11を封じる: 5パターン減少、奇数パターン割合10.5%に若干減少</li>
                <li>10を封じる: 5パターン減少、奇数パターン割合15.8%に若干増加</li>
                <li>9を封じる: 5パターン減少、奇数のみパターン5.3%に大幅減少</li>
                <li>12を封じる: 3パターン減少、奇数パターン割合ほぼ変化なし（14.3%）</li>
            </ul>

            <h3>20点を狙う場合（全24パターン：奇数のみ0、偶数のみ5、混合19）</h3>
            <ol>
                <li>12 + 8 (2手)</li>
                <li>12 + 7 + 1 (3手)</li>
                <li>12 + 6 + 2 (3手)</li>
                <li>12 + 5 + 3 (3手)</li>
                <li>11 + 9 (2手)</li>
                <li>11 + 8 + 1 (3手)</li>
                <li>11 + 7 + 2 (3手)</li>
                <li>11 + 6 + 3 (3手)</li>
                <li>11 + 5 + 4 (3手)</li>
                <li>10 + 10 (2手)</li>
                <li>10 + 9 + 1 (3手)</li>
                <li>10 + 8 + 2 (3手)</li>
                <li>10 + 7 + 3 (3手)</li>
                <li>10 + 6 + 4 (3手)</li>
                <li>9 + 9 + 2 (3手)</li>
                <li>9 + 8 + 3 (3手)</li>
                <li>9 + 7 + 4 (3手)</li>
                <li>9 + 6 + 5 (3手)</li>
                <li>8 + 8 + 4 (3手)</li>
                <li>8 + 7 + 5 (3手)</li>
                <li>7 + 7 + 6 (3手)</li>
                <li>6 + 6 + 8 (3手)</li>
                <li>5 + 5 + 10 (3手)</li>
                <li>4 + 4 + 12 (3手)</li>
            </ol>

            <h4>偶数のみパターン：</h4>
            <ul>
                <li>12 + 8</li>
                <li>10 + 10</li>
                <li>8 + 8 + 4</li>
                <li>6 + 6 + 8</li>
                <li>4 + 4 + 12</li>
            </ul>

            <h4>戦略的考察：</h4>
            <ul>
                <li>12, 11, 10を含むパターンが多く、これらが最重要スキットル</li>
                <li>2手パターンが3つ存在（12 + 8, 11 + 9, 10 + 10）</li>
                <li>奇数のみのパターンが存在しない</li>
                <li>偶数のみパターンが5つあり、相対的に多い</li>
            </ul>

            <h4>封じ込め戦略：</h4>
            <ul>
                <li>10を封じる: 5パターン減少、偶数のみパターン15.8%に若干減少</li>
                <li>9を封じる: 4パターン減少、偶数のみパターン25%に増加</li>
                <li>8を封じる: 4パターン減少、偶数のみパターン15%に減少</li>
                <li>11を封じる: 4パターン減少、偶数のみパターンほぼ変化なし（20%）</li>
                <li>12を封じる: 3パターン減少、偶数のみパターン14.3%に減少</li>
            </ul>        </CategoryContent>
      </div>

      <div>
        <CategoryTitle onClick={() => toggleCategory('basic-strategy')}>2. 基本戦略</CategoryTitle>
        <CategoryContent isActive={activeCategory === 'basic-strategy'}>
          <ul>
            <li>大きな点数のスキットルは常に遠くへ飛ばす</li>
            <li>ガシャは大きな点数を狙う</li>
          </ul>
        </CategoryContent>
      </div>

      <div>
        <CategoryTitle onClick={() => toggleCategory('opponent-strategy')}>3. 相手の得点に応じた戦略</CategoryTitle>
        <CategoryContent isActive={activeCategory === 'opponent-strategy'}>
        <ul>
                <li><strong>相手が奇数得点</strong>: 奇数スキットルを遠くに（自分が偶数得点時）</li>
                <li><strong>相手が偶数得点</strong>: 偶数スキットルを遠くに（自分が奇数得点時）</li>
                <li><strong>得点タイプが一致</strong>: 相手が狙いにくいスキットルを遠くに</li>
                <li><strong>得点タイプが不一致</strong>: 慎重に選択（自分の得点変化に注意）</li>
            </ul>
        </CategoryContent>
      </div>

      <div>
        <CategoryTitle onClick={() => toggleCategory('complete-sealing')}>4. 完全封じ込め戦略</CategoryTitle>
        <CategoryContent isActive={activeCategory === 'complete-sealing'}>
        <h3>完全封じ込め戦略</h3>

        <h4>25点の状況（25点必要）</h4>
        <ol>
            <li>12, 11, 10を最も遠い位置に配置</li>
            <li>7を12, 11, 10と同じく遠くに配置</li>
            <li>8, 6, 4, 2を分散配置</li>
            <li>1, 3, 5, 9を近くにまとめて配置</li>
        </ol>
        <p><strong>効果:</strong> 高得点スキットル（12, 11, 10）と7を封じることで、ほとんどのパターンを不可能にします。残りの偶数スキットルを分散させることで、8+8+9のパターンも困難にします。</p>

        <h4>26点の状況（24点必要）</h4>
        <ol>
            <li>すべての奇数スキットル（11, 9, 7, 5, 3, 1）を最も遠い位置に配置</li>
            <li>12, 10, 8, 6, 4, 2を近くに配置</li>
        </ol>
        <p><strong>効果:</strong> 24点は偶数のため、奇数スキットルのみの配置で完全封じ込みが可能です。</p>

        <h4>27点の状況（23点必要）</h4>
        <ol>
            <li>すべての偶数スキットル（12, 10, 8, 6, 4, 2）を最も遠い位置に配置</li>
            <li>11, 9, 7, 5, 3, 1を近くに配置</li>
        </ol>
        <p><strong>効果:</strong> 23点は奇数のため、偶数スキットルのみの配置で完全封じ込みが可能です。</p>

        <h4>28点の状況（22点必要）</h4>
        <ol>
            <li>11を最も遠い位置に配置</li>
            <li>10と12を離して配置し、10+12の組み合わせを困難に</li>
            <li>残りの偶数スキットル（8, 6, 4, 2）を分散配置</li>
            <li>奇数スキットル（9, 7, 5, 3, 1）を近くに集めて配置</li>
        </ol>
        <p><strong>効果:</strong> 11+11のパターンを封じつつ、他の主要なパターンも困難にします。</p>

        <h4>29点の状況（21点必要）</h4>
        <ol>
            <li>11と12を最も遠い位置に配置</li>
            <li>10, 9, 8, 7を中間距離に配置し、複数倒れを誘発（4点以下の得点に）</li>
            <li>6以下のスキットルを近くに配置するが、21点になる組み合わせを避ける</li>
        </ol>
        <p><strong>効果:</strong> 高得点スキットルを封じつつ、中得点スキットルの組み合わせも困難にします。</p>

        <h4>30点の状況（20点必要）</h4>
        <ol>
            <li>12, 11, 10を最も遠い位置に配置</li>
            <li>9, 8, 7を中間距離に配置し、複数倒れを誘発</li>
            <li>6以下のスキットルを近くに配置するが、できるだけ分散させる</li>
        </ol>
        <p><strong>効果:</strong> 完全封じ込めは困難ですが、高得点スキットルを封じつつ、中得点スキットルの組み合わせも困難にします。</p>

        <h4>一般的な完全封じ込め戦略のポイント</h4>
        <ul>
            <li>相手の必要得点が奇数の場合、偶数スキットルを遠ざける</li>
            <li>相手の必要得点が偶数の場合、奇数スキットルを遠ざける</li>
            <li>高得点スキットル（特に12, 11, 10）の配置を最優先で考慮する</li>
            <li>中得点スキットルの組み合わせも考慮し、適切に分散または集中させる</li>
            <li>低得点スキットルの配置で微調整し、完全な封じ込みを目指す</li>
        </ul>
        </CategoryContent>
      </div>

      <div>
        <CategoryTitle onClick={() => toggleCategory('turn-extension')}>5. 手数延長戦略</CategoryTitle>
        <CategoryContent isActive={activeCategory === 'turn-extension'}>
        <h3>手数延長戦略</h3>

        <h4>25点の状況（25点必要）</h4>
        <p><strong>最速手:</strong> 12+12+1 (3手)</p>

        <p><strong>4手に延長:</strong></p>
        <ol>
            <li>12を最も遠い位置に配置</li>
            <li>1を12から離して配置</li>
            <li>11, 10, 9を近くに集めて配置し、複数倒れを誘発</li>
        </ol>

        <p><strong>5手に延長:</strong></p>
        <ol>
            <li>上記に加えて、8, 7, 6も近くに配置</li>
            <li>5以下の小さい数字を分散配置</li>
        </ol>

        <h4>26点の状況（24点必要）</h4>
        <p><strong>最速手:</strong> 12+12 (2手)</p>

        <p><strong>3手に延長:</strong></p>
        <ol>
            <li>12を最も遠い位置に配置</li>
            <li>11, 10を12から離して配置</li>
            <li>9, 8, 7を近くに集めて配置</li>
        </ol>

        <p><strong>4手に延長:</strong></p>
        <ol>
            <li>上記に加えて、偶数スキットル（2, 4, 6, 8, 10, 12）を可能な限り分散配置</li>
            <li>奇数スキットルを集めて配置し、複数倒れを誘発</li>
        </ol>

        <h4>27点の状況（23点必要）</h4>
        <p><strong>最速手:</strong> 12+11 (2手)</p>

        <p><strong>3手に延長:</strong></p>
        <ol>
            <li>12と11を最も遠い位置に離して配置</li>
            <li>10, 9, 8を近くに集めて配置</li>
            <li>7以下の小さい数字を分散配置</li>
        </ol>

        <p><strong>4手に延長:</strong></p>
        <ol>
            <li>上記に加えて、偶数スキットルのみを使用可能にする配置</li>
            <li>12, 10, 8, 6, 4, 2を可能な限り分散配置</li>
        </ol>

        <h4>28点の状況（22点必要）</h4>
        <p><strong>最速手:</strong> 12+10 または 11+11 (2手)</p>

        <p><strong>3手に延長:</strong></p>
        <ol>
            <li>12, 11, 10を最も遠い位置に配置</li>
            <li>9, 8, 7を中間距離に配置</li>
            <li>6, 5, 4を近くに集めて配置</li>
        </ol>

        <p><strong>4手に延長:</strong></p>
        <ol>
            <li>12, 11, 10, 9を遠くに配置</li>
            <li>8, 7, 6を中間距離に配置</li>
            <li>5, 4, 3, 2を近くに集めて配置</li>
            <li>1を分散配置</li>
        </ol>

        <h4>29点の状況（21点必要）</h4>
        <p><strong>最速手:</strong> 12+9 または 11+10 (2手)</p>

        <p><strong>3手に延長:</strong></p>
        <ol>
            <li>12, 11, 10を最も遠い位置に分散配置</li>
            <li>9, 8, 7, 6を近くに集めて配置</li>
            <li>5以下の小さい数字を中間距離に配置</li>
        </ol>

        <p><strong>4手に延長:</strong></p>
        <ol>
            <li>上記に加えて、9も遠い位置に配置</li>
            <li>8, 7, 6, 5を近くに集めて配置</li>
            <li>4以下の小さい数字を分散配置</li>
        </ol>

        <h4>30点の状況（20点必要）</h4>
        <p><strong>最速手:</strong> 12+8 または 11+9 または 10+10 (2手)</p>

        <p><strong>3手に延長:</strong></p>
        <ol>
            <li>12, 11, 10を最も遠い位置に分散配置</li>
            <li>9, 8, 7を近くに集めて配置</li>
            <li>6以下の小さい数字を中間距離に配置</li>
        </ol>

        <p><strong>4手に延長:</strong></p>
        <ol>
            <li>上記に加えて、8, 9も遠い位置に配置</li>
            <li>7, 6, 5を近くに集めて配置</li>
            <li>4以下の小さい数字を分散配置</li>
        </ol>

        <h4>手数延長戦略の一般的なポイント</h4>
        <ul>
            <li>高得点スキットル（12, 11, 10）を優先的に遠ざける</li>
            <li>中得点スキットル（6-9）を集めて配置し、複数倒れを誘発</li>
            <li>低得点スキットル（1-5）を分散配置し、微調整を困難に</li>
            <li>相手の得点状況（偶数/奇数）を考慮し、適切なスキットルを遠ざける</li>
            <li>自分の次の手での得点可能性も考慮しながら配置を行う</li>
        </ul>
        </CategoryContent>
      </div>

      <div>
        <CategoryTitle onClick={() => toggleCategory('situational-analysis')}>6. シチュエーション別定量的戦略分析</CategoryTitle>
        <CategoryContent isActive={activeCategory === 'situational-analysis'}>
        <h3>シチュエーション別定量的戦略分析</h3>

        <h4>序盤戦略 (0-20点)</h4>

        <h5>シナリオ1: 開始直後</h5>
        <ul>
            <li><strong>戦略:</strong> 12を狙う</li>
            <li><strong>自分の有利度:</strong> +40% (12点獲得で一気にリード)</li>
            <li><strong>相手の不利度:</strong> -30% (最高得点スキットルの位置が不利に)</li>
        </ul>

        <h5>シナリオ2: 自分8点(偶数)、相手6点(偶数)</h5>
        <ul>
            <li><strong>戦略:</strong> 11と10を近づけて配置し、12を遠ざける</li>
            <li><strong>自分の有利度:</strong> +35% (21-22点に到達しやすい)</li>
            <li><strong>相手の不利度:</strong> -25% (18点到達が困難)</li>
        </ul>

        <h5>シナリオ3: 自分15点(奇数)、相手18点(偶数)</h5>
        <ul>
            <li><strong>戦略:</strong> 12, 11, 10を分散配置</li>
            <li><strong>自分の有利度:</strong> +30% (25-27点に一気に到達可能)</li>
            <li><strong>相手の不利度:</strong> -35% (30点到達が困難)</li>
        </ul>

        <h4>中盤戦略 (21-35点)</h4>

        <h5>シナリオ4: 自分28点(偶数)、相手31点(奇数)</h5>
        <ul>
            <li><strong>戦略:</strong> 11を最も遠くに配置、10と12を分散</li>
            <li><strong>自分の有利度:</strong> +45% (40点到達が容易)</li>
            <li><strong>相手の不利度:</strong> -40% (42-44点到達が困難)</li>
        </ul>

        <h5>シナリオ5: 自分33点(奇数)、相手29点(奇数)</h5>
        <ul>
            <li><strong>戦略:</strong> 偶数スキットル(12,10,8,6,4,2)を遠くに配置</li>
            <li><strong>自分の有利度:</strong> +50% (44-46点到達が容易)</li>
            <li><strong>相手の不利度:</strong> -45% (40点到達が困難)</li>
        </ul>

        <h5>シナリオ6: 自分26点(偶数)、相手35点(奇数)</h5>
        <ul>
            <li><strong>戦略:</strong> 12,11,10を最も遠くに、9,8,7を中間に配置</li>
            <li><strong>自分の有利度:</strong> +25% (38-40点に追いつく可能性)</li>
            <li><strong>相手の不利度:</strong> -30% (46-49点到達が困難)</li>
        </ul>

        <h4>終盤戦略 (36-49点)</h4>

        <h5>シナリオ7: 自分46点(偶数)、相手44点(偶数)</h5>
        <ul>
            <li><strong>戦略:</strong> 4を最も近くに、3,2,1を遠くに配置</li>
            <li><strong>自分の有利度:</strong> +70% (50点到達が容易)</li>
            <li><strong>相手の不利度:</strong> -65% (50点到達が極めて困難)</li>
        </ul>

        <h5>シナリオ8: 自分49点(奇数)、相手47点(奇数)</h5>
        <ul>
            <li><strong>戦略:</strong> 1を最も遠くに、2,3を中間に、4以上を近くに配置</li>
            <li><strong>自分の有利度:</strong> +80% (1点で勝利)</li>
            <li><strong>相手の不利度:</strong> -75% (3点獲得がほぼ不可能)</li>
        </ul>

        <h5>シナリオ9: 自分38点(偶数)、相手45点(奇数)</h5>
        <ul>
            <li><strong>戦略:</strong> 12,11,10,9を最も遠くに、8,7,6を中間に配置</li>
            <li><strong>自分の有利度:</strong> +40% (50点に一気に到達可能)</li>
            <li><strong>相手の不利度:</strong> -50% (50点到達が困難、かつ超過リスクあり)</li>
        </ul>

        <h4>定量的分析の基準</h4>
        <ul>
            <li><strong>有利度/不利度の計算基準:</strong>
                <ul>
                    <li>+/- 10%: わずかな影響</li>
                    <li>+/- 25%: 顕著な影響</li>
                    <li>+/- 50%: 決定的な影響</li>
                    <li>+/- 75%以上: ゲームを左右する影響</li>
                </ul>
            </li>
            <li><strong>スキットル配置の影響:</strong>
                <ul>
                    <li>遠距離配置: 該当スキットル獲得確率 -40%</li>
                    <li>中距離配置: 該当スキットル獲得確率 -20%</li>
                    <li>近距離配置: 該当スキットル獲得確率 +30%</li>
                </ul>
            </li>
            <li><strong>得点差による心理的影響:</strong>
                <ul>
                    <li>1-5点差: 心理的影響 ±0%</li>
                    <li>6-10点差: 追う側 -10%、リードする側 +10%</li>
                    <li>11-15点差: 追う側 -20%、リードする側 +20%</li>
                    <li>16点以上の差: 追う側 -30%、リードする側 +30%</li>
                </ul>
            </li>
        </ul>
        </CategoryContent>
      </div>
    </StrategyGuideContainer>
  );
};

export default StrategyGuide;