import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import TeamSelection from './components/TeamSelection';
import TeamInput from './components/TeamInput';
import TeamOrder from './components/TeamOrder';
import GameBoard from './components/GameBoard';
import Scoreboard from './components/Scoreboard';
import GameResult from './components/GameResult';
import TopScreen from './components/TopScreen';
import BackToTopButton from './components/BackToTopButton';

const AppContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;

  @media (min-width: 768px) {
    max-width: 800px;
    padding: 20px;
  }
`;

const Header = styled.h1`
  background-color: #3498db;
  color: white;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  margin: 0;
  text-align: center;
  font-size: 1.2rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`;

const BackButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 300px;

  &:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

function App() {
  const [isTopScreen, setIsTopScreen] = useState(true);
  const [step, setStep] = useState(1);
  const [teams, setTeams] = useState([]);
  const [currentGame, setCurrentGame] = useState(1);
  const [gameHistory, setGameHistory] = useState([]);
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
  const [activePins, setActivePins] = useState([]);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    console.log('App updated', { step, teams, currentPlayerIndex });
    if (teams.length > 0 && teams.some(team => team.score >= 50)) {
      setStep(5); // ゲーム終了画面に移動
    }
  }, [step, teams, currentPlayerIndex]);

  useEffect(() => {
    if (teams.length > 0) {
      setHistory([{ teams, currentPlayerIndex, activePins }]);
    }
  }, [teams, currentPlayerIndex, activePins]);

  const handleTeamSelection = useCallback((count) => {
    const newTeams = Array(count).fill().map((_, index) => ({
      id: `team-${index}`,
      name: `チーム${index + 1}`,
      score: 0,
      totalScore: 0,
      misses: 0
    }));
    console.log("handleTeamSelection - New Teams: ", newTeams);
    setTeams(newTeams);
    setStep(2);
  }, []);

  const handleTeamInput = useCallback((updatedTeams) => {
    console.log("handleTeamInput - Updated Teams: ", updatedTeams);
    setTeams(updatedTeams);
    setStep(3);
  }, []);

  const handleTeamOrder = useCallback((orderedTeams) => {
    console.log("handleTeamOrder - Ordered Teams: ", orderedTeams);
    setTeams(orderedTeams);
    setStep(4);
  }, []);

  const handlePinClick = useCallback((pinNumber) => {
    setActivePins(prev => 
      prev.includes(pinNumber) 
        ? prev.filter(pin => pin !== pinNumber)
        : [...prev, pinNumber]
    );
  }, []);

  const handleConfirm = useCallback(() => {
    const currentScore = activePins.reduce((sum, pin) => sum + pin, 0);
    const updatedTeams = teams.map((team, index) => {
      if (index === currentPlayerIndex) {
        const newScore = team.score + currentScore;
        const newMisses = currentScore === 0 ? team.misses + 1 : team.misses;
        return {
          ...team,
          score: newScore,
          totalScore: team.totalScore + newScore,
          misses: newMisses
        };
      }
      return team;
    });
    const nextPlayerIndex = (currentPlayerIndex + 1) % teams.length;
  
    setTeams(updatedTeams);
    setCurrentPlayerIndex(nextPlayerIndex);
    setActivePins([]);
    setHistory(prev => [...prev, { teams: updatedTeams, currentPlayerIndex: nextPlayerIndex, activePins: [] }]);
  }, [activePins, currentPlayerIndex, teams]);

  const handleUndo = useCallback(() => {
    if (history.length > 1) {
      const newHistory = history.slice(0, -1);
      const previousState = newHistory[newHistory.length - 1];
  
      setHistory(newHistory);
      setTeams(previousState.teams);
      setCurrentPlayerIndex(previousState.currentPlayerIndex);
      setActivePins(previousState.activePins);
    }
  }, [history]);
  
  const handleGameEnd = useCallback(() => {
    setGameHistory([...gameHistory, { teams: [...teams], gameNumber: currentGame }]);
    setStep(5);
  }, [gameHistory, teams, currentGame]);

  const handleBackToTop = useCallback(() => {
    setIsTopScreen(true);
    setStep(1);
    setTeams([]);
    setCurrentGame(1);
    setGameHistory([]);
    setCurrentPlayerIndex(0);
    setActivePins([]);
    setHistory([]);
  }, []);

  const renderStep = useCallback(() => {
    console.log('Rendering step', step);
    if (isTopScreen) {
      return <TopScreen onStartGame={() => {
        setIsTopScreen(false);
        setStep(1);
      }} />;
    }

    let content;
    switch(step) {
      case 1:
        content = <TeamSelection 
          onSelect={handleTeamSelection}
          onBack={() => setIsTopScreen(true)}
        />;
        break;
      case 2:
        content = <TeamInput 
          teams={teams} 
          onComplete={handleTeamInput}
          onBack={() => setStep(1)}
        />;
        break;
      case 3:
        content = <TeamOrder 
          teams={teams} 
          onComplete={handleTeamOrder}
          onBack={() => setStep(2)}
        />;
        break;
      case 4:
        content = (
          <>
            <GameBoard 
              teams={teams}
              currentGame={currentGame}
              currentPlayerIndex={currentPlayerIndex}
              activePins={activePins}
              onPinClick={handlePinClick}
              onConfirm={handleConfirm}
              onUndo={handleUndo}
            />
            {Array.isArray(teams) && teams.length > 0 && (
              <Scoreboard
                teams={teams}
                currentGame={currentGame}
                onGameEnd={handleGameEnd}
              />
            )}
            <BackButtonContainer>
              <BackButton onClick={() => setStep(3)}>戻る</BackButton>
            </BackButtonContainer>
          </>
        );
        break;
      case 5:
        content = <GameResult 
          teams={teams} 
          gameHistory={gameHistory}
          onNextGame={() => {
            setCurrentGame(currentGame + 1);
            setTeams(teams.map(team => ({ ...team, score: 0, misses: 0 })));
            setCurrentPlayerIndex((currentPlayerIndex + 1) % teams.length);
            setStep(4);
          }}
          onGameEnd={() => {
            setStep(1);
            setTeams([]);
            setCurrentGame(1);
            setGameHistory([]);
            setCurrentPlayerIndex(0);
          }}
          onBack={() => setStep(4)}
        />;
        break;
      default:
        content = null;
    }

    return (
      <>
        {content}
        <ButtonContainer>
          <BackToTopButton onClick={handleBackToTop} />
        </ButtonContainer>
      </>
    );
  }, [step, isTopScreen, teams, currentGame, currentPlayerIndex, gameHistory, handleTeamSelection, handleTeamInput, handleTeamOrder, activePins, handleConfirm, handleUndo, handleGameEnd, handleBackToTop, handlePinClick]);

  return (
    <AppContainer>
      {!isTopScreen && <Header>モルックゲーム - STEP {step}</Header>}
      {renderStep()}
    </AppContainer>
  );
}

export default App;