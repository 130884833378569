import React, { useEffect } from 'react';
import styled from 'styled-components';

const ScoreboardContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
`;

const ScoreboardHeader = styled.div`
  background-color: #3498db;
  color: white;
  padding: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  text-align: center;
`;

const ScoreboardRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  padding: 10px;
  border-bottom: 1px solid #eee;
  text-align: center;

  &:last-child {
    border-bottom: none;
  }
`;

const TeamName = styled.div`
  font-weight: bold;
`;

const MissIndicator = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.$missed ? '#e74c3c' : '#ecf0f1'};
  margin: 0 2px;
`;

const GameNumber = styled.div`
  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const Scoreboard = ({ teams = [], currentGame, onGameEnd }) => {
    useEffect(() => {
      if (Array.isArray(teams) && teams.length > 0 && teams.some(team => team.misses >= 3)) {
        onGameEnd();
      }
    }, [teams, onGameEnd]);
  
    if (!Array.isArray(teams) || teams.length === 0) {
      return <div>チームが設定されていません。</div>;
    }

    return (
        <ScoreboardContainer>
          <ScoreboardHeader>
            <div>チーム</div>
            <GameNumber>第{currentGame}ゲーム</GameNumber>
            <div>合計</div>
            <div>ミス</div>
          </ScoreboardHeader>
          {teams.map((team) => (
            <ScoreboardRow key={team.id}>
              <TeamName>{team.name}</TeamName>
              <div>{team.score}</div>
              <div>{team.totalScore}</div>
              <div>
                {[...Array(3)].map((_, i) => (
                  <MissIndicator key={i} $missed={i < team.misses} />
                ))}
              </div>
            </ScoreboardRow>
          ))}
        </ScoreboardContainer>
      );
    };
    
    export default Scoreboard;