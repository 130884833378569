import React from 'react';
import styled from 'styled-components';

const BoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
`;

const GameTitle = styled.h2`
  font-size: 1.5rem;
  margin: 10px 0;
`;

const PinContainer = styled.div`
  position: relative;
  width: 320px;
  height: 240px;
  margin-bottom: 5px;
`;

const Pin = styled.button`
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #3498db;
  background-color: ${props => props.$active ? '#3498db' : 'white'};
  color: ${props => props.$active ? 'white' : '#3498db'};
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  left: ${props => props.$left}px;
  top: ${props => props.$top}px;

  &:hover {
    background-color: #3498db;
    color: white;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
`;

const Button = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
`;

const GameBoard = ({ teams, currentPlayerIndex, activePins, onPinClick, onConfirm, onUndo }) => {
  const pinLayout = [
    { number: 7, left: 65, top: 0 },
    { number: 9, left: 130, top: 0 },
    { number: 8, left: 195, top: 0 },
    { number: 5, left: 32, top: 70 },
    { number: 11, left: 97, top: 70 },
    { number: 12, left: 162, top: 70 },
    { number: 6, left: 227, top: 70 },
    { number: 3, left: 65, top: 140 },
    { number: 10, left: 130, top: 140 },
    { number: 4, left: 195, top: 140 },
    { number: 1, left: 97, top: 210 },
    { number: 2, left: 162, top: 210 },
  ];

  return (
    <BoardContainer>
      <GameTitle>{teams[currentPlayerIndex].name}の番です</GameTitle>
      <PinContainer>
        {pinLayout.map(({ number, left, top }) => (
          <Pin
            key={number}
            $active={activePins.includes(number)}
            onClick={() => onPinClick(number)}
            $left={left}
            $top={top}
          >
            {number}
          </Pin>
        ))}
      </PinContainer>
      <ButtonContainer>
        <Button onClick={onUndo}>戻る</Button>
        <Button onClick={onConfirm}>決定</Button>
      </ButtonContainer>
    </BoardContainer>
  );
};

export default GameBoard;