import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

const Title = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
`;

const Button = styled.button`
  background-color: ${props => props.$gray ? '#95a5a6' : '#3498db'};
  color: white;
  border: none;
  padding: 15px 0;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  width: 100%;

  &:hover {
    background-color: ${props => props.$gray ? '#7f8c8d' : '#2980b9'};
  }
`;

const BackButtonContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const TeamSelection = ({ onSelect, onBack }) => {
  return (
    <Container>
      <Title>チーム数を選んでください</Title>
      <ButtonGrid>
        {[1, 2, 3, 4, 5, 6].map(num => (
          <Button key={num} onClick={() => onSelect(num)}>
            {num}チーム
          </Button>
        ))}
      </ButtonGrid>
      <BackButtonContainer>
        <Button $gray onClick={onBack}>戻る</Button>
      </BackButtonContainer>
    </Container>
  );
};

export default TeamSelection;