import React from 'react';
import styled from 'styled-components';

const ResultContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 10px auto;
  text-align: center;
  font-family: 'Arial', sans-serif;
`;

const TeamResult = styled.div`
  background-color: ${props => props.$winner ? '#f1c40f' : '#ecf0f1'};
  padding: 8px;
  margin: 6px 0;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }
`;

const TeamName = styled.h2`
  margin: 0 0 6px 0;
  font-size: 1rem;
  color: #2c3e50;
`;

const ScoreDisplay = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 6px;
`;

const ScoreBox = styled.div`
  background-color: white;
  border: 1px solid #3498db;
  border-radius: 4px;
  padding: 4px 8px;
  min-width: 120px;
  font-weight: bold;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 35px;
`;

const ScoreLabel = styled.div`
  font-size: 0.7rem;
  margin-bottom: 2px;
  color: #7f8c8d;
`;

const ScoreValue = styled.div`
  font-size: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
  gap: 12px;
`;

const Button = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 6px 16px;
  border-radius: 16px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: #2980b9;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }
  &:active {
    transform: translateY(0);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
`;

const GameResultTitle = styled.h1`
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

const GameResult = ({ teams, gameHistory, onNextGame, onGameEnd }) => {
  const sortedTeams = [...teams].sort((a, b) => b.score - a.score);

  return (
    <ResultContainer>
      <GameResultTitle>ゲーム結果</GameResultTitle>
      {sortedTeams.map((team, index) => (
        <TeamResult key={team.id} $winner={index === 0}>
          <TeamName>{index + 1}. {team.name}</TeamName>
          <ScoreDisplay>
            {gameHistory.map((game, gameIndex) => (
              <ScoreBox key={gameIndex}>
                <ScoreLabel>第{game.gameNumber}セット</ScoreLabel>
                <ScoreValue>{game.teams.find(t => t.id === team.id).score}</ScoreValue>
              </ScoreBox>
            ))}
            <ScoreBox>
              <ScoreLabel>合計</ScoreLabel>
              <ScoreValue>{team.score}</ScoreValue>
            </ScoreBox>
          </ScoreDisplay>
        </TeamResult>
      ))}
      <ButtonContainer>
        <Button onClick={onGameEnd}>ゲーム終了</Button>
        <Button onClick={onNextGame}>次のゲームへ</Button>
      </ButtonContainer>
    </ResultContainer>
  );
};

export default GameResult;