import React from 'react';
import styled from 'styled-components';
import StrategyGuide from './StrategyGuide';

const TopScreenContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding: 20px;
    max-width: 768px;
  }
`;

const TopHeader = styled.h1`
  background-color: #3498db;
  color: white;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  margin: 0 0 20px 0;
  text-align: center;
  font-size: 1.5rem;
  box-sizing: border-box;
  width: 100%;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const Button = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 15px 20px;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }

  @media (min-width: 768px) {
    font-size: 1.5rem;
    padding: 20px;
  }
`;

const TopScreen = ({ onStartGame }) => {
    const handleMolkkyGameClick = () => {
      window.location.href = 'https://molkkygame.nft-mint.xyz/';
    };
    
    return (
    <TopScreenContainer>
      <TopHeader>Molkky</TopHeader>
      <Button onClick={onStartGame}>モルックスコア</Button>
      <Button onClick={handleMolkkyGameClick}>モルックゲーム</Button>

      <StrategyGuide />
    </TopScreenContainer>
  );
};

export default TopScreen;