import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const TeamList = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  background-color: ${props => props.$gray ? '#95a5a6' : '#3498db'};
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Instructions = styled.p`
  text-align: center;
  margin-bottom: 10px;
  font-style: italic;
`;

const TeamOrder = ({ teams, onComplete, onBack }) => {
  const [orderedTeams, setOrderedTeams] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  useEffect(() => {
    console.log('TeamOrder mounted');
    return () => console.log('TeamOrder unmounted');
  }, []);

  useEffect(() => {
    if (teams.length > 0) {
      setOrderedTeams(teams);
      console.log("TeamOrder - Teams updated: ", teams);
      console.log("Draggable Items: ", teams.map(team => team.id));
      const timer = setTimeout(() => setIsLoaded(true), 100);
      return () => clearTimeout(timer);
    }
  }, [teams]);

  const handleDragEnd = useCallback((result) => {
    if (!result.destination) return;

    const items = Array.from(orderedTeams);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setOrderedTeams(items);
    console.log("Drag ended, new order: ", items);
  }, [orderedTeams]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Container>
        <h2>投げる順番を決めてください</h2>
        <Instructions>
          {isTouchDevice
            ? "長押しして上下にドラッグすると順番を変更できます"
            : "ドラッグ&ドロップで順番を変更できます"}
        </Instructions>
        <Droppable droppableId="teams-list">
          {(provided) => (
            <TeamList
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {orderedTeams.map((team, index) => (
                <Draggable 
                  key={team.id} 
                  draggableId={team.id} 
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        backgroundColor: snapshot.isDragging ? '#3498db' : '#f0f0f0',
                        color: snapshot.isDragging ? 'white' : 'black',
                        margin: '5px 0',
                        padding: '10px',
                        borderRadius: '5px'
                      }}
                    >
                      {team.name}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </TeamList>
          )}
        </Droppable>
      </Container>
      <ButtonContainer>
        <Button $gray onClick={onBack}>戻る</Button>
        <Button onClick={() => onComplete(orderedTeams)}>ゲーム開始</Button>
      </ButtonContainer>
    </DragDropContext>
  );
};

export default React.memo(TeamOrder);